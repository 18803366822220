import React, { useEffect, useState } from 'react';
import Page from '../components/Page';
import {
  Button as Btn,
  Card,
  Container,
  Stack,
  Typography,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import Iconify from '../components/Iconify';
import Scrollbar from '../components/Scrollbar';
import { toast, ToastContainer } from 'react-toastify';
import axios from 'axios';
import { Button, ModalBody, ModalFooter, ModalHeader, Spinner, Table, Modal, Alert } from 'reactstrap';

const Reagions =()=>{
  const [data,setData]=useState(null)
  const [isLog, setislog] = useState(false);
  const [isLogAdd, setislogAdd] = useState(false);
  const [isOpenAdd, setIsOpenAdd] = useState(false);
  const [isOpenEdit, setIsOpenEdit] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const initial={
    region_name: "",
    region_description: "",
    argo_zone: "",
    country: "",
    latitude: "",
    longitude: ""
  }
  const [addState, setAddState] = useState(initial);
  const openEditM=(item)=>{
    setAddState(item);
    setIsOpenEdit(true)
  }
  const closeEditM=()=>{
    setAddState(initial);
    setIsOpenEdit(false)
  }
  const openDelete=(item)=>{
    setAddState(item);
    setIsOpenDelete(true)
  }
  const closeDelete=()=>{
    setAddState(initial);
    setIsOpenDelete(false)
  }

  const getRegions=()=>{
    setislog(true)
    console.log("get")
    const options = {
      url: process.env.REACT_APP_BASE_URL + "/regions" ,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      }
    };
    axios(options)
      .then(response => {
        setislog(false)
          console.log(response.data)
          setData(response.data?.data);

      })
      .catch(err => {
        setislog(false)
        console.log(err.response);

        const errorMessage = err.response.data.msg ? err.response.data.msg : "Failed. Please try again or contact us"

        toast.error(errorMessage, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  }
  const addRegions=()=>{
    setislogAdd(true)
    console.log("get")
    const options = {
      url: process.env.REACT_APP_BASE_URL + "/regions" ,
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      },
      data:addState
    };
    axios(options)
      .then(response => {
        setislogAdd(false)
          console.log(response.data)
        toast.success(response.data.msg, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setAddState(initial)
        getRegions()

      })
      .catch(err => {
        setislogAdd(false)
        console.log(err.response);
        const errorMessage = err.response.data.msg ? err.response.data.msg : "Failed. Please try again or contact us"
        toast.error(errorMessage, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  }
  const editRegions=()=>{
    setislogAdd(true)
    console.log("get")
    delete addState.updatedAt
    delete addState.createdAt
    const options = {
      url: process.env.REACT_APP_BASE_URL + "/regions/"+addState.uid ,
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      },
      data:addState
    };
    axios(options)
      .then(response => {
        closeEditM()
        setislogAdd(false)
          console.log(response.data)
        toast.success(response.data.msg, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setAddState(initial)
        getRegions()

      })
      .catch(err => {
        setislogAdd(false)
        console.log(err.response);
        const errorMessage = err.response.data.msg ? err.response.data.msg : "Failed. Please try again or contact us"
        toast.error(errorMessage, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  }
  const deleteRegions=()=>{
    setislogAdd(true)
    console.log("get")
    delete addState.updatedAt
    delete addState.createdAt
    const options = {
      url: process.env.REACT_APP_BASE_URL + "/regions/"+addState.uid ,
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      },
      data:addState
    };
    axios(options)
      .then(response => {
        closeDelete()
        setislogAdd(false)
          console.log(response.data)
        toast.success(response.data.msg, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setAddState(initial)
        getRegions()

      })
      .catch(err => {
        setislogAdd(false)
        console.log(err.response);
        closeDelete()
        const errorMessage = err.response.data.msg ? err.response.data.msg : "Failed. Please try again or contact us"
        toast.error(errorMessage, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  }
  useEffect(()=>{
    getRegions()
  },[])
  return(
    <React.Fragment>

      <Page title="User">


      <Container>
        <Scrollbar>
        <Modal isOpen={isOpenAdd}
               size={"md"}
               centered={true}
               className="modal-ll"
        >
          <ModalHeader toggle={()=> {
            setIsOpenAdd(false);
            setAddState(initial)
          }} style={{borderBottom: "none"}}>
            Ajouter
          </ModalHeader>
          <ModalBody>
            <div className="row">
              <div className="col-12 p-1">
                <input className="form-control" value={addState.region_name} placeholder="Region name"
                       onChange={e=>setAddState({
                         ...addState,
                         region_name: e.target.value
                       })}
                />
              </div>
              <div className="col-12 p-1">
                <textarea className="form-control" value={addState.region_description}
                          placeholder="Region description"  onChange={e=>setAddState({
                  ...addState,
                  region_description: e.target.value
                })}
                />
              </div>
              <div className="col-6 p-1">
                <input className="form-control" placeholder="Argo zone" value={addState.argo_zone}
                       onChange={e=>setAddState({
                  ...addState,
                  argo_zone: e.target.value
                })}
                />
              </div>
              <div className="col-6 p-1">
                <input className="form-control" placeholder="Country" value={addState.country}
                       onChange={e=>setAddState({
                  ...addState,
                  country: e.target.value
                })}
                />
              </div>
              <div className="col-6 p-1">
                <input className="form-control" placeholder="Latitude" value={addState.latitude}
                       onChange={e=>setAddState({
                  ...addState,
                  latitude: e.target.value
                })}
                />
              </div>
              <div className="col-6 p-1">
                <input className="form-control" placeholder="Longitude" value={addState.longitude}
                       onChange={e=>setAddState({
                  ...addState,
                  longitude: e.target.value
                })}
                />
              </div>
            </div>
          </ModalBody>
          <ModalFooter style={{borderTop: "none"}}>
            {isLogAdd
                ?
                <div className="d-flex  col-12 align-items-center justify-content-center">
                  <Spinner/>
                </div>
                :
              <Button
              color='primary'
              onClick={addRegions}
            >
              Save
            </Button>}
          </ModalFooter>
        </Modal>
        <Modal isOpen={isOpenEdit}
               size={"md"}
               centered={true}
               className="modal-ll"
        >
          <ModalHeader toggle={closeEditM} style={{borderBottom: "none"}}>
            Edit {addState.region_name}
          </ModalHeader>
          <ModalBody>
            <div className="row">
              <div className="col-12 p-1">
                <input className="form-control" value={addState.region_name} placeholder="Region name"
                       onChange={e=>setAddState({
                         ...addState,
                         region_name: e.target.value
                       })}
                />
              </div>
              <div className="col-12 p-1">
                <textarea className="form-control" value={addState.region_description}
                          placeholder="Region description"  onChange={e=>setAddState({
                  ...addState,
                  region_description: e.target.value
                })}
                />
              </div>
              <div className="col-6 p-1">
                <input className="form-control" placeholder="Argo zone" value={addState.argo_zone}
                       onChange={e=>setAddState({
                  ...addState,
                  argo_zone: e.target.value
                })}
                />
              </div>
              <div className="col-6 p-1">
                <input className="form-control" placeholder="Country" value={addState.country}
                       onChange={e=>setAddState({
                  ...addState,
                  country: e.target.value
                })}
                />
              </div>
              <div className="col-6 p-1">
                <input className="form-control" placeholder="Latitude" value={addState.latitude}
                       onChange={e=>setAddState({
                  ...addState,
                  latitude: e.target.value
                })}
                />
              </div>
              <div className="col-6 p-1">
                <input className="form-control" placeholder="Longitude" value={addState.longitude}
                       onChange={e=>setAddState({
                  ...addState,
                  longitude: e.target.value
                })}
                />
              </div>
            </div>
          </ModalBody>
          <ModalFooter style={{borderTop: "none"}}>
            {isLogAdd
                ?
                <div className="d-flex  col-12 align-items-center justify-content-center">
                  <Spinner/>
                </div>
                :
              <Button
              color='primary'
              onClick={editRegions}
            >
              Edit
            </Button>}
          </ModalFooter>
        </Modal>
          <Modal isOpen={isOpenDelete}
               size={"md"}
               centered={true}
               className="modal-ll"
        >
          <ModalHeader toggle={closeDelete} style={{borderBottom: "none"}}>
            Delete {addState.region_name}
          </ModalHeader>
          <ModalBody>
            <div className="row">
              <div className="col-12 p-1">
                <Alert color="danger">
                  <p>
                    Voulez-vous supprimer cette region ({addState.region_name})
                  </p>
                </Alert>
              </div>
            </div>
          </ModalBody>
          <ModalFooter style={{borderTop: "none"}}>
            {isLogAdd
                ?
                <div className="d-flex  col-12 align-items-center justify-content-center">
                  <Spinner/>
                </div>
                :
              <Button
              color='danger'
              onClick={deleteRegions}
            >
              DELETE
            </Button>}
          </ModalFooter>
        </Modal>

        </Scrollbar>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Regions
          </Typography>
          <Btn variant="contained" component={RouterLink} to="#" startIcon={<Iconify icon="eva:plus-fill" />} onClick={e=>{
            e.preventDefault();
            setIsOpenAdd(true)
          }}>
            New Region
          </Btn>
        </Stack>

            {
              isLog
                ?
                <div className="d-flex align-items-center justify-content-center col-12 ">
                  <Spinner/>
                </div>
                :
              <Table size='sm' responsive={true}>
              <thead>
              <tr>
                <th>
                  #
                </th>
                <th>
                   Name
                </th>
                <th>
                  Argo zone
                </th>
                <th>
                  Country
                </th>
                <th>
                  Latitude
                </th>
                <th>
                  Longitude
                </th>
                <th>
                  Created At
                </th>
                <th>
                  Updated At
                </th>
                <th>
                  Actions
                </th>
              </tr>
              </thead>
              <tbody>
              {
                data
                ?
               data.map((item,index)=>   <tr key={index}>
                 <th scope='row'>
                   {index + 1}
                 </th>
                 <td>
                   {item.region_name}
                 </td>
                 <td>
                   {item.argo_zone}
                 </td>
                 <td>
                   {item.country}
                 </td>
                 <td>
                   {item.latitude}
                 </td>
                 <td>
                   {item.longitude}
                 </td>
                 <td>
                   {item.createdAt}
                 </td>
                 <td>
                   {item.updatedAt}
                 </td>
                 <td>
                   <Button
                     color='primary'
                     size="sm"
                     className="col-12 mb-1"
                     onClick={()=>openEditM(item)}
                   >
                     Edite
                   </Button>{' '}
                   <Button
                     color='danger'
                     size="sm"
                     className="col-12 "
                     onClick={()=>openDelete(item)}
                   >
                     Delete
                   </Button>
                 </td>
               </tr>)
                  :
                  null
              }

              </tbody>
            </Table>}

      </Container>
    </Page>
    </React.Fragment>
  )
}
export default Reagions;