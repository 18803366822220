import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Card,
  Stack,
  Container,
  Typography,
} from '@mui/material';
// components
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
import { Button, Spinner, Table } from 'reactstrap';
import axios from 'axios';
import { toast } from 'react-toastify';

// ----------------------------------------------------------------------


export default function User() {

  const [data,setData]=useState(null)
  const [isLog, setislog] = useState(false);
  const getUsers=()=>{
    setislog(true)
    console.log("get")
    const options = {
      url: process.env.REACT_APP_BASE_URL + "/users" ,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'Authorization':localStorage.getItem("TokenUser"),
      }
    };
    axios(options)
      .then(response => {
        setislog(false)
        console.log(response.data)
        setData(response.data?.data);

      })
      .catch(err => {
        setislog(false)
        console.log(err.response);

        const errorMessage = err.response.data.msg ? err.response.data.msg : "Failed. Please try again or contact us"

        toast.error(errorMessage, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  }
  useEffect(()=>{
    getUsers()
  },[])
  return (
    <Page title="User">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Users
          </Typography>
         {/* <Button variant="contained" component={RouterLink} to="#" startIcon={<Iconify icon="eva:plus-fill" />}>
            New User
          </Button>*/}
        </Stack>


        {
          isLog
            ?
            <div className="d-flex align-items-center justify-content-center col-12 ">
              <Spinner/>
            </div>
            :
            <Table size='sm' responsive={true}>
              <thead>
              <tr>
                <th>
                  #
                </th>
                <th>
                  First name
                </th>
                <th>
                  Last name
                </th>
                <th>
                  Email
                </th>
                <th>
                  Created At
                </th>
                <th>
                  Updated At
                </th>
                <th>
                  Actions
                </th>
              </tr>
              </thead>
              <tbody>
              {
                data
                  ?
                  data.map((item,index)=>
                    <tr key={index}>
                    <th scope='row'>
                      {index + 1}
                    </th>
                    <td>
                      {item.first_name}
                    </td>
                    <td>
                      {item.last_name}
                    </td>
                    <td>
                      {item.email}
                    </td>
                    <td>
                      {item.createdAt}
                    </td>
                    <td>
                      {item.updatedAt}
                    </td>
                    <td>
                      <Button
                        color='primary'
                        size="sm"
                        className="col-12 mb-1"
                        onClick={null}
                      >
                        Make Admin
                      </Button>{' '}
                    </td>
                  </tr>)
                  :
                  null
              }

              </tbody>
            </Table>}
      </Container>
    </Page>
  );
}
