/* eslint-disable */
import React, {useState, createContext, useEffect} from "react";
import {toast} from "react-toastify";
import axios from "axios";

const Context=createContext();

const AuthProviderWrapper = ({children}) => {
    const [isLogin, setIsLogin] = useState(false);
    const [userData, setUserData] = useState(null);
    const [userToken, setUserToken] = useState(null);
    const [isLoaderUser, setIsLoaderUser] = useState(false);
    const logout=async ()=>{
        localStorage.removeItem("TokenUser")
        localStorage.removeItem("userData")
        setUserToken(null)
        setUserData(null)
        setIsLogin(false)
        toast.success('Deconnexion avec success', {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
         window.location.href="/";
    }
    const login = (email,password) => {
        if (email && password){
            const dataState={
                "email": email,
                "password": password
            }
            setIsLoaderUser(true)
            console.log(dataState)
            const options = {
                url:`${process.env.REACT_APP_BASE_URL }/users/admin/login` ,
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8',
                },
                data:dataState
            };
            axios(options)
                .then(response => {
                    setIsLoaderUser(false)
                    if (response.data.status===200){
                        console.log(response.data)
                        setUserData(response.data.data.user)
                        setUserToken(response.data.data.token)
                        localStorage.setItem("TokenUser",response.data.data.token)
                        localStorage.setItem("userData",JSON.stringify(response.data.data.user))
                        setIsLogin(true)
                        toast.success(response?.data?.msg, {
                            position: "top-right",
                            autoClose: 1000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                       window.location.href="/";

                    }else {
                        toast.error(response?.data?.msg, {
                            position: "top-right",
                            autoClose: 1000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }

                })
                .catch(err => {
                    setIsLoaderUser(false)
                    console.log(err.response.data);
                    const errorMessage = err.response.data.msg ? err.response.data.msg : "Failed. Please try again or contact us"
                    toast.error(errorMessage, {
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                });
        }else {
            toast.error('Remplisez tout le champs', {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }
    useEffect(()=>{
        if (localStorage.getItem("TokenUser") && localStorage.getItem("userData")){
            setIsLogin(true);
            setUserToken(localStorage.getItem("TokenUser"))
            setUserData(JSON.parse(localStorage.getItem("userData")))
        }
    },[])
    return (
        <Context.Provider value={{login,
            isLogin,
            isLoaderUser,
            userToken,
            userData,
            logout,
        }}>
            {children}
        </Context.Provider>
    )

}
export {AuthProviderWrapper, Context as AuthContext}