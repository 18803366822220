// routes
import { RouterProtect,Router } from './routes';
// theme
import ThemeProvider from './theme';
// components
import ScrollToTop from './components/ScrollToTop';
import { BaseOptionChartStyle } from './components/chart/BaseOptionChart';
import { ToastContainer } from 'react-toastify';
import React from 'react';

// ----------------------------------------------------------------------

export default function App() {
  return (
    <ThemeProvider>
      <ScrollToTop />
      <BaseOptionChartStyle />
      {
        localStorage.getItem("TokenUser") && localStorage.getItem("userData")
          ?
          <RouterProtect/>
          :
          <Router />
      }
      <ToastContainer />
    </ThemeProvider>
  );
}
